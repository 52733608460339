<template>
  <div class="container">
    <img
      class="hidden-sm-and-down"
      style="width: 100%"
      src="../../../assets/mock/us/组 7476.png"
      alt=""
    />
    <img
      class="hidden-md-and-up"
      style="width: 100%"
      src="../../../assets/mock/us/组 12944.png"
      alt=""
    />
    <div>
      <img class="hidden-md-and-up" style="width: 100%;" src="../../../assets/mock/us/地图.jpg" alt="" />
      <div class="top">
        <h3>联系我们</h3>
        <span></span>
        <p>contact us</p>
      </div>
      <div
        class="warp hidden-sm-and-down"
        style="margin-bottom: 3.125rem; position: relative"
      >
        <img
          style="width: 100%"
          src="../../../assets/mock/cooperate/2054.jpg"
          alt=""
        />
        <div class="title">
          <ul>
            <li>
              <img src="../../../assets/mock/cooperate/组 1106.png" alt="" />
            </li>
            <li>
              <img src="../../../assets/mock/cooperate/组 1091.png" alt="" />
              <p>电话</p>
              <p>021-55220166</p>
            </li>
            <li>
              <img src="../../../assets/mock/cooperate/组 1089.png" alt="" />
              <p>邮箱</p>
              <p>yuqi@yuqidata.com</p>
            </li>
            <li>
              <img src="../../../assets/mock/cooperate/组 1087.png" alt="" />
              <p>地址</p>
              <p>上海市黄浦区徐家汇路 610号3201室（日月光办公楼）</p>
            </li>
          </ul>
        </div>
        <h2 style="display: block; font-size: 1.875vw; color: #9e9e9e">
          SMART LIFE IS ONE STEP CLOSER
        </h2>
        <h2 style="font-size: 1.4583vw">智能生活 更近一步</h2>
      </div>
      <div class="warp hidden-md-and-up">
        <p>电话：<span>021-55220166</span></p>
        <p>邮箱：<span>yuqi@yuqidata.com</span></p>
        <p>
          地址: <span>上海市黄浦区徐家汇路 610号3201室（日月光办公楼）</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.75rem;
  margin: 0.625rem 0.9375rem;
}
.top {
  text-align: center;
  h3 {
    margin-top: 1.25rem;
  }
  span {
    width: 1.25rem;
    height: 3px;
    display: inline-block;
    background-color: #3cb59e;
  }
}
.title {
  width: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  ul {
    display: flex;
    background-color: #fff;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    li {
    }
  }
}
</style>
